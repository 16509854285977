<template>
  <div
    :id="id"
    :disabled="success"
    :class="{
      'tw-cursor-wait': loader
    }"
    role="button"
    class="ctk-btn-file tw-cursor-pointer tw-flex tw-justify-center tw-text-sm flex-fixed"
    @click.stop.capture.prevent="requestFiles"
  >
    <slot v-if="(!loader && !error) || success" />
    <ui-loader
      v-else-if="loader"
      :size="35"
      :white="white"
      type="spinner"
    />

    <i
      v-else
      class="icon-ctk-warning"
      aria-hidden="true"
    />

    <b-tooltip
      v-if="!success && (tooltipText || showTooltipError)"
      :show.sync="showTooltipError"
      :target="id"
    >
      {{ error ? $t('app.paragraphs.error_while_downloading_file') : tooltipText }}
    </b-tooltip>

    <!-- Download popover -->
    <b-popover
      v-if="popoverVisible"
      ref="popover"
      :target="id"
      :show.sync="popoverVisible"
      :disabled="true"
      placement="bottom"
    >
      <template #title>
        <div
          class="ctk-btn-file__tooltip tw-flex tw-justify-between tw-items-center"
        >
          <span
            v-text="popoverTitle"
            class="tw-font-normal tw-text-center"
          />
          <ui-button
            type="button"
            :title="$t('close') | capitalize"
            class="ctk-btn-file__tooltip__close close-popover"
            variant="link"
            size="sm"
            @click="onPopoverClose"
          >
            <template #left-icon>
              <ui-ctk-icon
                name="close"
              />
            </template>
          </ui-button>
        </div>
      </template>
      <div class="tw-flex">
        <ui-button
          v-if="files.view"
          id="view-button"
          :href="files.view"
          rel="noopener"
          variant="info"
          target="_blank"
          class="mr-2"
          size="sm"
          @click.capture="viewFile"
        >
          {{ $t('app.buttons.view') }}
        </ui-button>
        <ui-button
          v-if="files.download"
          id="download-button"
          :href="files.download"
          :download="fileName"
          rel="noopener"
          variant="info"
          target="_blank"
          size="sm"
          @click.capture="downloadFile"
        >
          {{ $t('app.buttons.download') | capitalize }}
        </ui-button>
      </div>
    </b-popover>
  </div>
</template>

<script>
  import axios from '@/resources/axios'

  /**
   * @module component - ctkBtnFile
   * @param {string} url - Request URL
   * @param {string} [fileName='chronotruck-document.pdf'] - The filename used to save the file
   * @param {string} [id='ctk-btn-file'] - Unique id for this button
   * @param {string|boolean} [tooltipText=false] - Default tooltip text
   * @param {string} popoverTitle - Text content in the popover element
   */
  export default {
    name: 'CtkBtnFile',
    components: {
    },
    props: {
      white: {
        type: Boolean,
        default: false
      },
      tooltipText: {
        type: [String, Boolean],
        default: false
      },
      popoverTitle: {
        type: String,
        required: true
      },
      url: {
        type: String,
        default: null
      },
      fileName: {
        type: String,
        default: 'chronotruck-document.pdf'
      },
      id: {
        type: String,
        default: 'ctk-btn-file'
      }
    },
    data () {
      return {
        loader: false,
        error: false,
        showTooltipError: false,
        success: false,
        popoverVisible: false,
        files: {
          view: null,
          download: null
        }
      }
    },
    watch: {
      url () {
        this.success = false
        this.toggleError(false)
        this.loader = false
      }
    },
    methods: {
      /**
       * Request the download of the files.
       * @function requestFiles
       */
      requestFiles () {
        this.loader = true
        this.popoverVisible = false
        this.toggleError(false)

        const downloadFile = axios.get(this.url, {
          params: {
            disposition: 'attachment'
          }
        })
          .then((response) => {
            this.files.download = response.data.url
            return response
          })

        const viewFile = axios.get(this.url)
          .then((response) => {
            this.files.view = response.data.url
            return response
          })

        /**
         * Wait for both files to be ready, to open the popover.
         */
        return Promise.all([
          viewFile,
          downloadFile
        ])
          .then((response) => {
            this.success = true
            this.popoverVisible = true

            this.$emit('success', response)
          })
          .catch(() => {
            this.toggleError(true)
            this.$emit('failed')
          })
          .finally(() => {
            this.loader = false

            /**
             * Reset the button state to it's default state after some seconds.
             */
            setTimeout(() => {
              this.toggleError(false)
              this.resetAsDefault()
            }, 2000)
          })
      },
      /**
       * Reset the button to it's initial default state.
       * @function resetAsDefault
       */
      resetAsDefault () {
        this.loader = false
        this.success = false
      },
      /**
       * @function toggleError
       */
      toggleError (val) {
        this.error = val
        this.showTooltipError = val
      },
      viewFile () {
        this.$emit('view')
        this.onPopoverClose()
      },
      downloadFile () {
        this.$emit('download')
        this.onPopoverClose()
      },
      /**
       * @function onPopoverClose
       */
      onPopoverClose () {
        this.popoverVisible = false
      }
    }
  }
</script>

<style lang="scss">
.ctk-btn-file {
  outline: 2px solid transparent;
  outline-offset: 2px;
  padding: 0px;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  -webkit-box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
  height: 35px;
  background: transparent;
  border-radius: 0 !important;
}
.ctk-btn-file i {
  font-size: 35px;
}
.ctk-btn-file i.icon-ctk-warning {
  color: orangered;
}
.ctk-btn-file:disabled {
  cursor: default;
}
</style>
