<template>
  <div
    :class="[!!secondaryValue ? 'tw-items-end' : 'tw-items-center', {'is-blue': blue, 'no-arrow': withoutArrow}]"
    class="pricing-total tw-flex tw-flex-col tw-rounded tw-px-4 tw-py-2"
  >
    <div class="tw-flex tw-justify-between tw-w-full">
      <div
        class="pricing-total-label tw-font-medium tw-pr-4 tw-flex tw-items-center tw-text-base"
        v-text="$options.filters.capitalize(label)"
      />
      <div
        class="tw-flex tw-flex-col tw-items-end"
      >
        <span
          v-if="secondaryValue"
          class="pricing-total-value pricing-total-value-secondary"
          v-text="secondaryValue"
        />
        <span
          class="pricing-total-value pricing-total-primary-value tw-font-medium tw-text-lg"
          v-text="primaryValue"
        />
      </div>
    </div>
    <slot />
  </div>
</template>

<script>
  /**
   * Generic component to show the total pricing, with a slot for the value
   * @module component - pricingTotal
   * @param {string} label
   * @param {string} value
   *
   * @example
   * <pricing-total
   *  :label="'Pricing label'"
   *  :value="'123,45 €"
   * />
   */
  export default {
    name: 'PricingTotal',
    props: {
      label: {
        type: String,
        required: true
      },
      primaryValue: {
        type: String,
        required: true,
        default: String
      },
      secondaryValue: {
        type: String,
        required: false,
        default: String
      },
      blue: {
        type: Boolean,
        default: false
      },
      withoutArrow: {
        type: Boolean,
        default: false
      }
    }
  }
</script>

<style lang="scss" scoped>

  $arrow-size: 10px;

  .pricing-total {
    position: relative;
    background-color: white;

    &-label {
      color: $secondary-text;
    }

    &-value {
      color: $info;
      white-space: nowrap;
      margin: auto 0;
    }

    &::after {
      content: ' ';
      position: absolute;
      bottom: -($arrow-size);
      left: 10px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: $arrow-size $arrow-size 0 $arrow-size;
      border-color: white transparent transparent transparent;
    }

    &.is-blue {
      background-color: $info;
      box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);

      .pricing-total-value,
      .pricing-total-label {
        color: white;
      }

      &::after {
        border-color: $info transparent transparent transparent;
      }
    }

    &.no-arrow::after {
      content: none;
    }
  }

</style>
