<template>
  <div class="pricing-invoice-header tw-flex tw-flex-col p-3">
    <div class="tw-flex tw-justify-between mb-1">
      <span
        class="tw-text-gray-700 pricing-ref"
        data-test="reference"
        v-text="invoice.reference"
      />
      <span
        :class="[invoice.state === 'paid' ? 'bg-teal' : 'bg-cyan']"
        v-text="$options.filters.capitalize($t(state))"
        class="chip tw-text-white tw-rounded pl-2 pr-2 info fs-12 tw-flex tw-items-center pricing-state"
        data-test="state"
      />
    </div>
    <div class="tw-flex tw-justify-between">
      <span
        :class="[invoiceTypeClass]"
        v-text="invoice.type.label"
        class="pricing-type"
        data-test="label"
      />
      <span
        class="tw-text-blue-500 text-right pricing-amount"
        data-test="pricing-amounts"
      >
        <span
          v-text="$t('price_excl_tax', {
            price: getCurrencyFormat(invoice.amount)
          })"
          data-test="amount-excl-tax"
        />
        <br>
        <span
          class="tw-font-medium fs-18 pricing-amount-price"
          v-text="$t('price_incl_tax', {
            price: getCurrencyFormat(invoice.vat_included_amount)
          })"
          data-test="amount-incl-tax"
        />
      </span>
    </div>
  </div>
</template>

<script>
  /**
   * @module component - PricingInvoiceHeader
   * @param {object} invoice
   * @param {string} currency
   */
  export default {
    name: 'PricingInvoiceHeader',
    props: {
      invoice: {
        type: Object,
        required: true
      },
      currency: {
        type: String,
        required: true
      }
    },
    computed: {
      /**
       * @function state
       * @returns {string}
       */
      state () {
        const { state, payment } = this.invoice
        if (state !== 'paid') return 'pricing.invoice.waiting_payment'

        return payment && payment.method === 'credit_card'
          ? 'pricing.invoice.paid_cc'
          : 'pricing.invoice.paid'
      },
      invoiceTypeClass () {
        const primaryInvoiceTypes = ['P', 'C2']
        return primaryInvoiceTypes.includes(this.invoice.type.key)
          ? 'tw-text-blue-500'
          : 'tw-text-red-500'
      }
    },
    methods: {
      getCurrencyFormat (number) {
        return this.$options.filters.currency(number, this.currency, this._i18n.locale)
      }
    }
  }
</script>

<style lang="scss" scoped>

  .pricing-invoice-header {
    border-bottom: 1px solid #F4F5F5;

    .tw-text-red-500 {
      color: lighten($danger, 10%) !important;
    }
  }

</style>
