
<template>
  <div class="pricing-invoice tw-bg-white tw-rounded mb-3">
    <pricing-invoice-header
      :invoice="invoice"
      :currency="currency"
    />

    <pricing-invoice-content
      :invoice="invoice"
      :currency="currency"
      :has-download-button="isUserShipper"
      @toggle-detail="toggle"
    />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import PricingInvoiceHeader from './_subs/PricingInvoiceHeader'
  import PricingInvoiceContent from './_subs/PricingInvoiceContent'

  export default {
    name: 'PricingInvoice',
    components: {
      PricingInvoiceHeader,
      PricingInvoiceContent
    },
    props: {
      invoice: {
        type: Object,
        default: Object,
        required: true
      },
      currency: {
        type: String,
        default: String,
        required: true
      }
    },
    computed: {
      ...mapGetters([
        'isUserShipper'
      ])
    },
    methods: {
      toggle (v) {
        if (this.$matomo) {
          this.$matomo.trackEvent('Invoices', v
            ? 'Expanded Complementary Informations'
            : 'Reduced Complementary Informations', this.invoice.uuid)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>

  .pricing-invoice {
    overflow: hidden;
  }

</style>
