<template>
  <div class="pricing-invoice-content">
    <button
      class="pricing-invoice-content-header px-3 py-2 tw-w-full p-0"
      @click="toggleDetail"
    >
      <div class="tw-flex tw-justify-between">
        <span class="tw-flex-1 text-left tw-font-normal title">
          {{ $t('pricing.invoice.further_information') }}
        </span>
        <div
          :class="{'is-open': hasDetailOpenned}"
          class="pricing-invoice-content-button tw-flex tw-items-center tw-justify-center"
        >
          <ui-ctk-icon
            name="close"
            data-test="icon"
          />
        </div>
      </div>
    </button>

    <b-collapse
      id="pricingInvoiceContentCollapse"
      v-model="hasDetailOpenned"
    >
      <pricing-layout
        data-test="dates"
        class="mb-3"
      >
        <pricing-lines
          :label="$t('pricing.invoice.issued_on')"
          data-test="issue-date"
          class="px-3"
          dark
        >
          <span>{{ $moment(invoice.issue_date).format('LL') }}</span>
        </pricing-lines>

        <pricing-lines
          v-if="!invoice.payment"
          :label="$t('pricing.invoice.due_to')"
          data-test="payment-date"
          class="px-3"
          dark
        >
          <span>{{ $moment(invoice.due_date).format('LL') }}</span>
        </pricing-lines>
      </pricing-layout>
      <p
        v-if="invoice.lines && invoice.lines.length > 0 || invoice.payment"
        data-test="detailled-invoice-title"
        class="px-3 py-2 m-0 tw-font-normal title"
      >
        {{ $t('pricing.invoice.detailed_invoice') }}
      </p>

      <pricing-layout
        class="mb-3"
        data-test="detailled-invoice"
      >
        <pricing-lines
          v-for="line in invoice.lines"
          :key="line.key"
          :label="line.label"
          :cancelled="line.is_cancelled"
          class="px-3"
          data-test="invoice-lines"
          dark
        >
          <span
            v-if="line.amount"
            class="tw-text-blue-500 text-right"
          >
            {{ getCurrencyFormat(line.amount) }}
          </span>
        </pricing-lines>

        <pricing-lines
          v-if="invoice.payment"
          :label="$t('pricing.invoice.payment.paid_on')"
          data-test="paid-on"
          class="px-3"
          dark
        >
          <span class="tw-text-blue-500 text-right">
            {{ $moment(invoice.payment.issue_date).format('LL') }}
          </span>
        </pricing-lines>

        <pricing-lines
          v-if="invoice.payment"
          :label="$t('pricing.invoice.payment.reference')"
          class="px-3"
          data-test="payment-reference"
          dark
        >
          <span class="tw-text-blue-500 text-right">
            {{ invoice.payment.reference }}
          </span>
        </pricing-lines>
      </pricing-layout>

      <div
        v-if="hasDownloadButton"
        class="pricing-invoice-content__footer p-3 tw-flex tw-items-center"
      >
        <ctk-btn-file
          :id="`download-button-${invoice.reference}`"
          :url="fileUrl"
          :popover-title="$t('app.labels.invoice')"
          :file-name="`invoice-${invoice.reference}.pdf`"
          class="tw-w-full"
          data-test="invoice-button"
          @view="viewFile"
          @download="downloadFile"
        >
          <ui-button
            variant="info"
            class="tw-w-full"
            data-test="pod-btn"
            @click="requestFile"
          >
            {{ $t('billing.buttons.download_invoice') }}
          </ui-button>
        </ctk-btn-file>
      </div>
    </b-collapse>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { defineComponent } from '@vue/composition-api'

  import PricingLines from '@/components/CtkPricing/_subs/PricingLines/index.vue'
  import PricingLayout from '@/components/CtkPricing/_subs/PricingLayout.vue'
  import CtkBtnFile from '@/components/CtkBtnFile/index.vue'

  /**
   * @module component - PricingInvoiceContent
   * @param {object} invoice
   * @param {string} currency
   * @param {boolean} [hasDownloadButton=false]
   */
  export default defineComponent({
    name: 'PricingInvoiceContent',
    components: {
      PricingLines,
      PricingLayout,
      CtkBtnFile
    },
    props: {
      invoice: {
        type: Object,
        default: Object,
        required: true
      },
      currency: {
        type: String,
        default: String,
        required: true
      },
      hasDownloadButton: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        hasDetailOpenned: false
      }
    },
    computed: {
      ...mapGetters('auth', [
        'getCid'
      ]),
      fileUrl () {
        return `v2/companies/${this.getCid}/invoices/${this.invoice.uuid}/download`
      }
    },
    methods: {
      getCurrencyFormat (number) {
        return this.$options.filters.currency(number, this.currency, this._i18n.locale)
      },
      toggleDetail () {
        this.hasDetailOpenned = !this.hasDetailOpenned
        this.$emit('toggle-detail', this.hasDetailOpenned)
      },
      requestFile () {
        if (this.$matomo) {
          this.$matomo.trackEvent('Invoices', 'Initiated CMR Download', this.invoice.uuid)
        }
      },
      viewFile () {
        if (this.$matomo) {
          this.$matomo.trackEvent('Invoices', 'Confirmed CMR Preview', this.invoice.uuid)
        }
      },
      downloadFile () {
        if (this.$matomo) {
          this.$matomo.trackEvent('Invoices', 'Confirmed CMR Download', this.invoice.uuid)
        }
      }
    }
  })
</script>

<style lang="scss" scoped>

  $button-size: 20px;

  .pricing-invoice-content {
    .title {
      color: #505050;
    }

    &-header {
      cursor: pointer;
      background-color: white;
      border: none;
      outline: none;
    }

    &-button {
      background-color: #CCC;
      border-radius: $button-size;
      color: white;
      height: $button-size;
      width: $button-size;
      padding: 0;
      transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
      transform: rotateZ(-45deg);

      i {
        font-size: $button-size - 4;
        font-weight: 600;
      }

      &.is-open {
        transform: rotateZ(0);
      }
    }
  }

  .chip {
    padding: 2px 4px;
  }

</style>
