<template>
  <div class="tw-flex tw-justify-between tw-items-center mb-2 pricing-invoices-title">
    <h5
      class="tw-flex tw-font-normal m-0"
    >
      <ui-ctk-icon
        name="clip"
        data-test="icon"
      />
      <span>{{ $tc('pricing.invoices.associated_invoices', count) }}</span>
    </h5>
    <span class="pricing-invoices-title__badge tw-text-white tw-rounded pl-3 pr-3 tw-flex tw-items-center tw-bg-blue-500 tw-justify-center tw-font-medium">
      {{ count === null ? '-' : $n(count) }}
    </span>
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  /**
   * @module component - pricingInvoicesTitle
   */
  export default defineComponent({
    name: 'PricingInvoicesTitle',
    props: {
      count: {
        type: Number,
        default: null
      }
    }
  })
</script>

<style lang="scss" scoped>

  .pricing-invoices-title {
    h5 {
      .ctk-font {
        font-size: 28px;
      }

      .ctk-font,
      span {
        margin: auto 0;
      }
    }

    &__badge {
      border-radius: 20px;
      min-height: 22px;
      padding: 2px 15px;
    }
  }

</style>
